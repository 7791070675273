import React from "react";
import Helmet from "react-helmet";

import config from '../../data/SiteConfig'
import Layout from "../layout";
import SEO from "../components/SEO/SEO";

import '../../scss/templates/page-template.scss'

class PageTemplate extends React.Component {
  constructor(props) {
    super(props);

    console.log(this.props);
    this.state = {};
  }


  render() {
    const { frontmatter, html } = this.props.pageContext; // data.markdownRemark holds our post data
    return (
      <Layout>
        <Helmet title={`${config.siteTitleAlt} | ${frontmatter.title || ''}`} />
        <SEO {...this.props} />

        <section className='content' dangerouslySetInnerHTML={{ __html: html }} />
      </Layout>
    );
  }
}

export default PageTemplate;

